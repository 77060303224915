import {
  useApi,
  useResources,
  useResourcesWithPII,
  useVimUserMetadata,
} from '@getvim/internal-vim-os-sdk/react';
import {
  ActivationStatus,
  ReferralWithoutPII,
  PatientWithoutPII,
  UpdatableReferral,
  PatientProblemList,
} from '@getvim/internal-vim-os-sdk/types';
import { useCallback, useEffect, useState } from 'react';
import { logic } from '../logic';
import { useGlobalState } from '../stores/GlobalStore';
import { GlobalStateActionType } from '../stores/globalStore.types';
import { OptumReferralAppEventsEnum } from '../analytics/types/appEvents.types';
import { createLogger } from '../utils';
import { useAnalytics } from './useAnalytics';
import { ActionStatus, AppConfig, Screens, SelectAction } from '../types';
import { getProviderAnalyticsProperties } from '../analytics/parseProviderForAnalytics';
import { getReferralMode, ReferralModeEnum } from '../logic/referral-request/referralMode';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { buildReferralWriteback } from './buildReferallWriteback';
import { usePrevious } from './usePrevious';
import { convertICDCodesToString } from '../utils/icdConverter';

const logger = createLogger('useEhrStateHandlers');
/**
 * A custom hook to handle ehr state reactive logic
 */
const useEhrStateHandlers = ({ appConfig }: { appConfig: AppConfig }) => {
  const { dispatch, state } = useGlobalState();
  const { ehrEvent } = useResources();
  const {
    ehrState: { patient, referral },
  } = useResourcesWithPII();
  const previousReferral: ReferralWithoutPII | undefined = usePrevious(referral);
  const previousPatient: PatientWithoutPII | undefined = usePrevious(patient);
  const { user, organization, ehrVendor } = useVimUserMetadata();

  const [submittedReferralsMap, setSubmittedReferralsMap] = useState<Record<string, string>>({});

  const {
    setActivationStatus,
    autoPopup,
    resourceUpdate,
    launchButton,
    closeApp,
    subscribeAppOpenStatus,
  } = useApi();

  const { analyticsClient } = useAnalytics();
  const [isActive, setIsActive] = useState<boolean>(false);
  const wasActive = usePrevious(isActive);

  const [shouldGetReferralModeByDateFF] = useFeatureFlag({
    flagName: 'referral_utility.shouldGetAthenaReferralModeByDate',
    defaultValue: false,
    team: Team.Interfaces,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });

  //track app enabled
  const trackAppEnabled = useCallback(
    (currentPatient: PatientWithoutPII, currentReferral: ReferralWithoutPII) => {
      analyticsClient.track(OptumReferralAppEventsEnum.APP_ENABLED, {
        ehr_insurance: currentPatient?.insurance?.ehrInsurance,
        vim_patient_id: currentPatient?.identifiers?.vimPatientId,
        patient_token: currentPatient?.token,
        linked_ehr_user_name: user?.identifiers?.ehrUsername,
        organization_id: organization?.identifiers?.id,
        organization_name: organization?.identifiers?.name,
        vimReferralId: currentReferral?.identifiers?.vimReferralId,
        ehrReferralId: currentReferral?.identifiers?.ehrReferralId,
      });
      analyticsClient.track(OptumReferralAppEventsEnum.BUTTON_CALCULATED, {
        referral: currentReferral,
        button_displayed: true,
      });
    },
    [analyticsClient, organization, user],
  );

  //on app disabled
  useEffect(() => {
    if (!isActive && wasActive) {
      dispatch({
        type: GlobalStateActionType.REFERRAL_CLOSED,
        payload: {
          optumIframeId: undefined,
          url: null,
          screen: Screens.None,
        },
      });
    }
  }, [dispatch, isActive, wasActive]);

  const setInlineButtonActive = useCallback(
    (active: boolean) => {
      launchButton.enableLaunchButtons({ referral: { REFERRAL_SELECT_PROVIDER: active } });
    },
    [launchButton],
  );
  const onActiveChanged = useCallback(
    (isActiveNow) => {
      if (isActiveNow) {
        setActivationStatus(ActivationStatus.ENABLED);
        setInlineButtonActive(true);
      } else {
        setActivationStatus(ActivationStatus.DISABLED);
        setInlineButtonActive(false);
      }
    },
    [setActivationStatus, setInlineButtonActive],
  );
  useEffect(() => {
    onActiveChanged(isActive);
  }, [onActiveChanged, isActive]);

  // ----------------------------------------
  // Auto popup logic
  // ----------------------------------------
  const checkAutoPopup = useCallback(
    async (currentReferral: ReferralWithoutPII, signal: AbortSignal) => {
      const { autoPopUpMode } = appConfig;

      const referralMode = await getReferralMode({
        referral: currentReferral,
        ehrVendor,
        shouldGetReferralModeByDateFF,
      });
      const referralFirstOpened = !previousReferral;
      const referralWasSwitched =
        previousReferral &&
        previousReferral.identifiers?.vimReferralId !== currentReferral.identifiers?.vimReferralId;
      if (
        !signal.aborted &&
        (referralWasSwitched || referralFirstOpened) &&
        ((referralMode === ReferralModeEnum.CREATE && autoPopUpMode?.create) ||
          (referralMode === ReferralModeEnum.EDIT && autoPopUpMode?.edit))
      ) {
        autoPopup();
      }
    },
    [appConfig, autoPopup, previousReferral, ehrVendor, shouldGetReferralModeByDateFF],
  );

  //----------------------------------------
  // Set app and button status based on referral and patient
  //----------------------------------------

  const toggleEnabledStatus = useCallback(
    async (
      currentPatient: PatientWithoutPII | undefined,
      currentReferral: ReferralWithoutPII | undefined,
      signal: AbortSignal,
    ): Promise<void> => {
      if (!currentPatient || !currentReferral) {
        setIsActive(false);
        return;
      }
      const { insurancesPlanList, supportAllInsurances } = appConfig;
      const isPatientEligible = logic.isPatientEligible({
        patient: currentPatient,
        insurancesPlanList,
        supportAllInsurances,
      });
      try {
        const isReferralReady = await logic.isReferralValid({
          referral: currentReferral,
          patient: currentPatient,
        });
        if (signal.aborted) {
          return;
        }
        if (isPatientEligible && isReferralReady) {
          setIsActive(true);
          trackAppEnabled(currentPatient, currentReferral);

          checkAutoPopup(currentReferral, signal).catch((error) => {
            logger.error('Error while checking auto popup', { error });
          });
        } else {
          setIsActive(false);
        }
      } catch (error) {
        logger.error('Error while checking if referral is valid', { error });
      }
    },
    [appConfig, checkAutoPopup, trackAppEnabled],
  );
  const [_enabledStateAbortController, setEnabledStateAbortController] = useState<
    AbortController | undefined
  >();
  useEffect(() => {
    if (referral !== previousReferral || patient !== previousPatient) {
      const controller = new AbortController();
      setEnabledStateAbortController((current) => {
        if (current) {
          current.abort();
        }
        return controller;
      });
      toggleEnabledStatus(patient, referral, controller.signal).catch((error) => {
        logger.error('Error in toggleEnabledStatus', { error });
      });
    }
  }, [toggleEnabledStatus, referral, patient, previousReferral, previousPatient, analyticsClient]);

  // ----------------------------------------
  // After referral is saved, send it to Optum
  // ----------------------------------------
  const sendReferralSavedToOptum = useCallback(
    async (ehrEvent) => {
      const eventReferral = ehrEvent.payload?.referral || ehrEvent.payload;
      await logic.sendReferralSavedToOptum({
        referral: eventReferral,
        currentReferralOptumId: submittedReferralsMap[eventReferral.vimReferralId],
        optumDeployConfiguration: appConfig.optumDeployConfiguration,
      });
    },
    [appConfig.optumDeployConfiguration, submittedReferralsMap],
  );
  useEffect(() => {
    if (ehrEvent && ehrEvent.type === 'referralSaved' && appConfig.optumDeployConfiguration) {
      void sendReferralSavedToOptum(ehrEvent);
    }
  }, [ehrEvent, appConfig, sendReferralSavedToOptum]);

  //////////////////////////////////////////
  // Close app
  //////////////////////////////////////////
  const onClose = useCallback(() => {
    closeApp();
    dispatch({ type: GlobalStateActionType.SET_SCREEN, payload: { screen: Screens.None } });
  }, [closeApp, dispatch]);

  //////////////////////////////////////////
  // on open flow
  // 1. Set to loading screen
  // 2. Get problem list
  // 3. Get url from optum
  // 4. Set screen to url from option
  //////////////////////////////////////////

  const onPageLoading = useCallback(
    async (
      currentPatient: PatientWithoutPII,
      currentReferral: ReferralWithoutPII,
      signal: AbortSignal,
    ) => {
      let patientProblemList: PatientProblemList;
      const onOpenContext = {
        referralId: currentReferral.identifiers?.vimReferralId,
        ehrReferralId: currentReferral.identifiers?.ehrReferralId,
        patientId: currentPatient.identifiers?.vimPatientId,
        insurance: currentPatient.insurance?.ehrInsurance,
      };
      try {
        logger.info('Start getting patientProblemList', {
          noPHI: true,
          ...onOpenContext,
        });
        const patientProblemListRaw = await currentPatient.getProblemList();
        patientProblemList = convertICDCodesToString(patientProblemListRaw);
      } catch {
        logger.error('Error getting patient problem list', { ...onOpenContext, noPHI: true });
      }
      if (signal.aborted) {
        return;
      }

      try {
        const data = await logic.referralRequest({
          referral: currentReferral,
          patient: currentPatient,
          deployConfig: appConfig.optumDeployConfiguration,
          problemList: patientProblemList,
        });
        logger.info('Got referral data from optum', {
          noPHI: true,
          optumIframeId: data!.referenceId,
          ...onOpenContext,
        });
        if (!data) {
          logger.error('Getting invalid optum response', {
            response: data,
            ...onOpenContext,
            noPHI: true,
          });
          throw new Error('Getting invalid optum response');
        }
        if (signal.aborted) {
          return;
        }
        dispatch({
          type: GlobalStateActionType.SET_SCREEN,
          payload: {
            url: data!.redirectUrl,
            screen: Screens.OptumSearchProvider,
            optumIframeId: data!.referenceId,
          },
        });
      } catch (error) {
        logger.error('Failed getting optum response', { error, ...onOpenContext, noPHI: true });
        if (signal.aborted) {
          return;
        }
        dispatch({
          type: GlobalStateActionType.SET_SCREEN,
          payload: {
            url: null,
            screen: Screens.OptumSearchProvider,
          },
        });
      }
    },
    [appConfig.optumDeployConfiguration, dispatch],
  );
  useEffect(() => {
    const controller = new AbortController();
    if (patient && referral && state.screen === Screens.Loading) {
      onPageLoading(patient, referral, controller.signal).catch((error) => {
        logger.error('Error while opening app', { error });
      });
    }
    return () => {
      controller.abort();
    };
  }, [onPageLoading, patient, referral, state.screen]);

  const onOpen = useCallback(
    (isManual: boolean) => {
      if (!referral || !patient) {
        return;
      }
      const onOpenContext = {
        referralId: referral.identifiers?.vimReferralId,
        ehrReferralId: referral.identifiers?.ehrReferralId,
        patientId: patient?.identifiers?.vimPatientId,
        insurance: patient?.insurance?.ehrInsurance,
      };
      logger.info('Before app open', { noPHI: true, ...onOpenContext });
      if (isManual) {
        analyticsClient.track(OptumReferralAppEventsEnum.VIM_SELECT_BUTTON_CLICKED, {
          referral,
        });
      }
      dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          screen: Screens.Loading,
        },
      });
    },
    [analyticsClient, dispatch, patient, referral],
  );

  useEffect(() => {
    const unsubscribe = subscribeAppOpenStatus((status) => {
      if (!status) {
        return;
      }
      const { isAppOpen, appOpenTrigger } = status;
      if (isAppOpen) {
        //Due to the fact that SDK functions are not ensured to complete in order,
        //there may be a case where the app autoPopup was completed after the app was set to disabled
        if (!isActive) {
          onActiveChanged(false);
        } else {
          onOpen(appOpenTrigger !== 'auto_popup');
        }
      } else {
        onClose();
      }
    });
    return unsubscribe;
  }, [subscribeAppOpenStatus, onOpen, onClose, isActive, onActiveChanged]);

  const [shouldUseLeadingReachProvider] = useFeatureFlag({
    flagName: 'referral_utility.shouldUseLeadingReachProvider',
    defaultValue: false,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });
  const [shouldUseLeadingReachProviderReferToName] = useFeatureFlag({
    flagName: 'referral_utility.shouldUseLeadingReachProviderReferToName',
    defaultValue: false,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });

  const trackTargetProviderSelectedWithVim = useCallback(
    ({
      selectedProvider,
      status,
    }: {
      selectedProvider: UpdatableReferral['targetProvider'];
      status: ActionStatus;
    }) => {
      const previousProvider = referral?.targetProvider;

      analyticsClient.track(OptumReferralAppEventsEnum.TARGET_SELECTED_WITH_VIM, {
        ...getProviderAnalyticsProperties(previousProvider, selectedProvider),
        actionDetails: {
          status,
          selectAction: SelectAction.WRITEBACK,
        },
      });
    },
    [analyticsClient, referral?.targetProvider],
  );

  const onReferralSubmit = useCallback(async () => {
    if (!state.optumIframeId) {
      throw new Error('no optumIframeId in state');
    }
    const writeBackContext = {
      stateOptumIframeId: state.optumIframeId,
      deployConfig: appConfig.optumDeployConfiguration,
      vimReferralId: referral?.identifiers?.vimReferralId,
    };
    const data = await logic.referralResponse({
      referral,
      optumReferralId: state.optumIframeId,
      deployConfig: appConfig!.optumDeployConfiguration!,
    });
    if (!data || !data.referralId) {
      return logger.error("Didn't get a referral id from Optum response", {
        ...writeBackContext,
        referral: data,
        noPHI: true,
      });
    }
    logger.info(`got referral from optum after response`, {
      ...writeBackContext,
      referral: data,
      noPHI: true,
    });

    const vimReferralId = referral?.identifiers?.vimReferralId;
    if (vimReferralId) {
      setSubmittedReferralsMap((current) => ({
        ...current,
        [vimReferralId]: data.referralId,
      }));
    } else {
      logger.warning('Failed adding submitted referral to map, vimReferralId is missing', {
        referral,
        optumResult: data,
        noPHI: true,
      });
    }

    const referralToUpdate: UpdatableReferral = await logic.getUpdateReferralByQueryResponse(
      data,
      shouldUseLeadingReachProvider,
      shouldUseLeadingReachProviderReferToName,
    );

    logger.info('updating referral with new data', { ...writeBackContext, noPHI: true });
    const builder = resourceUpdate.referralBuilder();
    buildReferralWriteback(builder, referralToUpdate);

    try {
      await builder.commit();
      logger.info('updating referral submited', { ...writeBackContext, noPHI: true });
      trackTargetProviderSelectedWithVim({
        selectedProvider: referralToUpdate.targetProvider,
        status: ActionStatus.SUCCESS,
      });
      analyticsClient.track(OptumReferralAppEventsEnum.REFERRAL_FINISHED, {
        vimReferralId: referral?.identifiers?.vimReferralId,
        optumReferralId: data?.referralId,
      });
      onClose();
    } catch (error) {
      logger.error('writeback failed', { error, ...writeBackContext, noPHI: true });
      trackTargetProviderSelectedWithVim({
        selectedProvider: referralToUpdate.targetProvider,
        status: ActionStatus.FAILED,
      });
      return dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          screen: Screens.WriteBackError,
        },
      });
    }

    const submittedReferralData = referralToUpdate;
    const { basicInformation, targetProvider, conditions, procedureCodes } = submittedReferralData;
    analyticsClient.track(OptumReferralAppEventsEnum.RU_REFERRAL_SUBMITTED, {
      submittedReferralData: {
        optumReferralId: state.optumIframeId,
        targetProvider: targetProvider,
        specialty: basicInformation?.specialty,
        diagnosis: conditions?.diagnosis,
        startDate: basicInformation?.startDate,
        endDate: basicInformation?.endDate,
        priority: basicInformation?.priority,
        cpts: procedureCodes?.cpts,
        numberOfVisits: basicInformation?.numberOfVisits,
      },
    });
  }, [
    analyticsClient,
    appConfig,
    dispatch,
    onClose,
    referral,
    resourceUpdate,
    shouldUseLeadingReachProvider,
    shouldUseLeadingReachProviderReferToName,
    state.optumIframeId,
    trackTargetProviderSelectedWithVim,
  ]);

  // ----------------------------------------
  // Events handler
  // ----------------------------------------
  const lastAction = state.lastAction;
  const previousAction = usePrevious(lastAction);
  useEffect(() => {
    if (lastAction !== previousAction) {
      switch (lastAction?.type) {
        case GlobalStateActionType.ON_SUBMIT: {
          onReferralSubmit().catch((error) => {
            logger.error('Error while submitting referral', { error });
          });
          break;
        }
        case GlobalStateActionType.ON_CLOSE: {
          onClose();
          break;
        }
      }
    }
  }, [lastAction, onClose, onReferralSubmit, previousAction]);
};

export default useEhrStateHandlers;
